<template>
    <div class="imagegroup">
        <div class="group-layout">
            <transition name="fade" mode="out-in">
                <div
                    @click.stop="background = !background"
                    class="lightbox-background"
                    v-if="background"
                ></div>
            </transition>
            <div
                class="image-box"
                v-for="(design, index) in designs"
                :key="design.id + '_' + index"
                :class="computeClass(index)"
                @click="lightboxEffect(index, designs)"
            >
                <OverLayCard
                    :title="useBy == 'theme' ? design.space : design.theme"
                    :img="selectImage(design.image, index)"
                />

                <div v-if="background" class="lightbox">
                    <div v-if="windowWidth < 1100">
                        <div class="lightbox-mobile">
                            <div class="mobile-header">
                                <p class="image-heading">
                                    {{ images[currentImage].space }}
                                </p>
                                <div
                                    class="mobile-close"
                                    @click.stop="background = !background"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="24"
                                        class="close-button"
                                        fill="none"
                                        viewBox="0 0 25 24"
                                    >
                                        <path
                                            fill="#fff"
                                            d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                        />
                                    </svg>
                                </div>
                            </div>
                            <div class="lightbox-mobile-image">
                                <img
                                    @click.stop="background = true"
                                    :key="currentImage"
                                    :src="images[currentImage].image.w_image"
                                    class="lightbox-image"
                                />
                            </div>
                            <div class="mobile-arrow" v-if="images.length > 1">
                                <div @click.stop="prev" class="left">
                                    <svg
                                        width="25"
                                        height="24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style="transform: scale(2);"
                                    >
                                        <path
                                            d="M15.675 7.41 14.267 6l-5.992 6 5.992 6 1.408-1.41L11.1 12l4.574-4.59z"
                                            fill="#fff"
                                        />
                                    </svg>
                                </div>
                                <div @click.stop="next" class="right">
                                    <svg
                                        width="25"
                                        height="24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style="transform: scale(2);"
                                    >
                                        <path
                                            d="M10.283 6 8.875 7.41 13.449 12l-4.574 4.59L10.283 18l5.992-6-5.992-6z"
                                            fill="#fff"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="lightbox-header">
                            <p class="lightbox-image-heading">
                                {{ images[currentImage].space }}
                            </p>
                            <div
                                class="lightbox-close"
                                @click.stop="background = !background"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="24"
                                    class="close-button"
                                    fill="none"
                                    viewBox="0 0 25 24"
                                >
                                    <path
                                        fill="#fff"
                                        d="M19.275 6.41L17.865 5l-5.59 5.59L6.685 5l-1.41 1.41 5.59 5.59-5.59 5.59L6.685 19l5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59 5.59-5.59z"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div class="lightbox-body">
                            <div
                                v-if="images.length > 1"
                                @click.stop="prev"
                                class="lightbox-prev lightbox-btn"
                            >
                                <svg
                                    width="25"
                                    height="24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="transform: scale(2);"
                                >
                                    <path
                                        d="M15.675 7.41 14.267 6l-5.992 6 5.992 6 1.408-1.41L11.1 12l4.574-4.59z"
                                        fill="#fff"
                                    />
                                </svg>
                            </div>

                            <div v-if="background" class="lightbox-container">
                                <transition name="fade" mode="out-in">
                                    <img
                                        @click.stop="background = true"
                                        :key="currentImage"
                                        :src="
                                            images[currentImage].image.w_image
                                        "
                                        class="lightbox-image"
                                    />
                                </transition>
                            </div>

                            <div
                                v-if="images.length > 1"
                                @click.stop="next"
                                class="lightbox-next lightbox-btn"
                            >
                                <svg
                                    width="25"
                                    height="24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style="transform: scale(2);"
                                >
                                    <path
                                        d="M10.283 6 8.875 7.41 13.449 12l-4.574 4.59L10.283 18l5.992-6-5.992-6z"
                                        fill="#fff"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OverLayCard from 'componentsv2/OverLayCard'
import { mapState, mapActions } from 'vuex'

export default {
    name: 'ImageGroup',
    components: {
        OverLayCard,
    },
    props: {
        useBy: String,
        designs: {
            type: Array,
            // required: true
        },
        layoutType: {
            type: String,
        },
    },
    data() {
        return {
            background: false,
            currentImage: 0,
            images: [],
        }
    },
    watch: {
        background(e) {
            if (e) {
                document.querySelector('body').style.overflow = 'hidden'
            } else {
                document.querySelector('body').style.overflow = 'auto'
            }
        },
    },
    computed: {
        ...mapState({
            windowWidth: state => state.AppModule.windowWidth,
        }),
    },
    methods: {
        lightboxEffect(index, imagearr) {
            this.currentImage = index
            this.background = !this.background
            this.images = imagearr
        },
        next() {
            if (this.currentImage < this.images.length - 1) {
                this.currentImage++
            } else {
                this.currentImage = 0
            }
        },
        prev() {
            if (this.currentImage > 0) {
                this.currentImage--
            } else {
                this.currentImage = this.images.length - 1
            }
        },
        computeClass(index) {
            switch (this.layoutType) {
                case '1':
                    if (index == 0 || index == 5) {
                        // console.log('1 w')
                        return 'col-span-2'
                    } else {
                        // console.log('1 n')
                        return ''
                    }
                case '2':
                    if (index == 3) {
                        // console.log('2 l')
                        return 'row-span-2'
                    } else if (index == 4) {
                        // console.log('2 w')
                        return 'col-span-2'
                    } else {
                        // console.log('2 n')
                        return ''
                    }
                case '3':
                    if (index == 2 || index == 3) {
                        // console.log('3 w')
                        return 'col-span-2'
                    } else {
                        // console.log('3 n')
                        return ''
                    }
                case '4':
                    if (index == 0) {
                        // console.log('4 l')
                        return 'row-span-2'
                    } else if (index == 2) {
                        // console.log('4 w')
                        return 'col-span-2'
                    } else {
                        // console.log('4 n')
                        return ''
                    }
                case '5':
                    return ''
                default:
                // console.log('no layout selected')
            }
        },
        selectImage(images, index) {
            switch (this.layoutType) {
                case '1':
                    if (index == 0 || index == 5) {
                        // console.log('1 w')
                        return images.w_image
                    } else {
                        // console.log('1 n')
                        return images.n_image
                    }
                case '2':
                    if (index == 3) {
                        // console.log('2 l')
                        return images.l_image
                    } else if (index == 4) {
                        // console.log('2 w')
                        return images.w_image
                    } else {
                        // console.log('2 n')
                        return images.n_image
                    }
                case '3':
                    if (index == 2 || index == 3) {
                        // console.log('3 w')
                        return images.w_image
                    } else {
                        // console.log('3 n')
                        return images.n_image
                    }
                case '4':
                    if (index == 0) {
                        // console.log('4 l')
                        return images.l_image
                    } else if (index == 2) {
                        // console.log('4 w')
                        return images.w_image
                    } else {
                        // console.log('4 n')
                        return images.n_image
                    }
                case '5':
                    return images.n_image
                default:
                // console.log('no layout selected')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import './ImageGroup.scss';
</style>
